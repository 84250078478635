import React , { ChangeEvent , useEffect, useState , useCallback } from 'react'
import * as loginService from '../service/login'
import { AlertObject, User } from "../types/types"
import Loading  from "../components/Loading"
import Button from "../components/Button"
import Alert from "../components/Alert"
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";


function Login() {

    const navigate = useNavigate();
    const location = useLocation();
    //let isLogout = location.state ? location.state.logout : false;
    const [username,setUsername] =  useState(localStorage.getItem('tracking.username') || '')
    const [password,setPassword] =  useState(localStorage.getItem('tracking.password') || '')

    
    let { logout } = useParams();

    useEffect(()=>{
        console.log('username-login',username)
        console.log('password-login',password)
        if(username && username!='' && password && password!=''){
            navigate('/')
        }
    },[])

    const [alert,setAlert] =  useState<AlertObject | undefined>()

    function onChangeUsername(element : ChangeEvent<HTMLInputElement>){
        setUsername(element.currentTarget.value)
    }


    function onChangePassword(element : ChangeEvent<HTMLInputElement>){
        setPassword(element.currentTarget.value)
    }

    async function login(){
        if(username == '' || password == ''){
            setAlert({title:'Errore',message:'Username e Password obbligatori'})
            return
        }
        window.loadingCenter.addLoading(true)
        let user : User = {
            username: username,
            password: password,
            type: 'cookie'
        }
        try{
            let response = await loginService.doLogin(user)
            console.log('response',response)

            window.loadingCenter.removeLoading()

            if(!response){
                setAlert({title:'Errore',message:'Errore durante la login'})          
                return
            }
            
            if(response.status == 200){
                localStorage.setItem('tracking.username', username);
                localStorage.setItem('tracking.password', password);
                navigate('/')
            }else{
                console.log(response)
    
                localStorage.setItem('tracking.username', '');
                localStorage.setItem('tracking.password', '');
    
                let errorData = await response.json()
                if(errorData.message){
                    setAlert({title:'Errore',message:errorData.message})
                }else{
                    setAlert({title:'Errore',message:'Errore durante la login'})                
                }
                
            }
        }catch(e){
            console.error(e)
            setAlert({title:'Errore',message:'Errore durante la login'})                
        }

        
    }
    
    
    return (
        <div className="w-full ">
            {
                alert &&
                <Alert title={alert.title} text={alert.message} closeModal={()=>setAlert(undefined)} />
            }
            <div className="h-screen flex flex-col justify-center items-center bg-primary">
                <div className="flex flex-col justify-center items-center bg-white ml-4 mr-4">
                <div className="w-4/5 pt-8">
                    <img className="w-full max-w-xs" src="/montorfano_logo_blu.png"/>
                </div>
                <div className="flex flex-col justify-center items-center pl-20 pr-20 pt-10 pb-10">
                    <div className="pb-6">
                        <div>Username</div>
                        <input type="text" onChange={onChangeUsername} value={username} />
                    </div>
                    <div>
                        <div>Password</div>
                        <input type="password" onChange={onChangePassword} value={password} />
                    </div>
                    <Button text="Login" clickAction={login}/>
                </div>
                
                </div>
            </div>
        </div>
        
    )
}

export default Login;
