import React , { useState } from 'react'


type ButtonProps= {
    text: string,
    clickAction?: Function
}

export default function Button(props : ButtonProps){

    function onClick(){
        if(props.clickAction){
            props.clickAction()
        }
    }

    return (
        <button onClick={onClick} className="flex justify-center items-center bg-primary text-white w-24 h-12 mt-10 cursor-pointer">
            {props.text}
        </button>
      )
}
