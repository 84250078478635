import { Damage, Tracking } from '../types/types'
import * as fetch from '../utility/fetch'
import * as localStorageService from './localStorage'

export const sendDamage = async function(damage : Damage){
    let params : fetch.FetchParamsPost = {
      url: '/api/damage',
      body: damage
    }
  
    const response = await fetch.post(params)
  
    return response
}
