
import {User} from '../types/types'
import * as fetch from '../utility/fetch'

export const doLogin = async function(user : User){
    let params : fetch.FetchParamsPost = {
      url: '/api/login',
      body: user
    }

    const response = await fetch.post(params)

    return response    
}


export const getUserProfile = async function(){
  let params : fetch.FetchParamsPost = {
    url: '/api/profile'
  }

  const response = await fetch.get(params)

  return response
}

export const logout = async function(){
  let params : fetch.FetchParamsPost = {
    url: '/api/logout'
  }

  const response = await fetch.get(params)

  return response
}