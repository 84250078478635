import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
function Template(props) {
    console.log('props',props)
    return (
        <div className="flex flex-col w-full justify-between">
            <div className="flex flex-col h-fit items-center justify-start">
                <Header title={props.title} back={props.back} logout={props.logout} />            
            </div>
            {React.cloneElement(props.children, { isDanno:props.isDanno })}

            {
                props.footer && 
                <div className="absolute bottom-0 w-screen flex justify-center pb-4">
                    <Footer />
                </div>
            }
            
        </div>
    );
}
  
export default Template;