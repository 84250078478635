import {
    CameraIcon,
    QrCodeIcon
} from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { goToQRCode , goToCamera } from '../service/router'



function Homepage() {
    const navigate = useNavigate();


    /*async function checkPermission(){
        const permissions = await navigator.mediaDevices.getUserMedia({audio: false, video: true})
        console.log('permissions',permissions)
    }*/

    useEffect(()=>{
        //checkPermission()
    },[])


    return (
        <div className="flex flex-col items-center justify-start bg-white h-full w-screen">
          <div className="flex flex-row bg-primary w-2/3 h-16 mt-8 items-center justify-center cursor-pointer" onClick={()=>{goToQRCode(navigate)}}>
            <div className="flex w-1/3 items-center justify-center">
                <QrCodeIcon className="h-6 w-6 ml-2 mt-[2px] text-white text-lg "/>
            </div>
            <div className="w-2/3 font-medium text-white">
                Scansione QR Code
            </div>
          </div>
          <div className="flex flex-row bg-primary w-2/3 h-16 mt-8 items-center justify-center cursor-pointer" onClick={()=>{goToCamera(navigate)}}>
            <div className="flex w-1/3 items-center justify-center">
                <CameraIcon className="h-6 w-6 ml-2 mt-[2px] text-white text-lg "/>
            </div>
            <div className="w-2/3 font-medium text-white">
                Segnala Danno
            </div>
          </div>
        </div>
    );
  }
  
  export default Homepage;
  