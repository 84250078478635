import { useState , useEffect , useRef , useCallback } from "react";
import Webcam from "react-webcam";
import MediaDevices from 'media-devices'
import Select from 'react-select'

function CameraSnap(props) {
    const webcamRef = useRef(null);
    const [selectedDeviceId,setSelectedDeviceId] = useState(null)
    const [devices,setDevices] = useState(null)

    const videoConstraints = {
        facingMode: { exact: "environment" }
    };

    async function getMediaDevices(){
        let devices = await MediaDevices.enumerateDevices()
        let videoDevices = []
        for(let i=0;i<devices.length;i++){
            let device = devices[i]
            if(device.kind=="videoinput"){
                videoDevices.push({label:device.label,value:device.deviceId})
            }
        }
        setDevices(videoDevices)
        //set first device selected
        if(videoDevices.length>0){
            setSelectedDeviceId(videoDevices[0].value)
        }        
    }

    useEffect(()=>{
        //getMediaDevices()
    },[])

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            return imageSrc
        },
        [webcamRef]
    );

    function snapPhoto(){
        let img = capture()
        console.log('img',img)
        props.saveImage(img)
    }
    
    function changeCamera(deviceId){
        setSelectedDeviceId(null)
        setTimeout(()=>{
            console.log('deviceId',deviceId)
            setSelectedDeviceId(deviceId)
        },100)
    }
  
    return (<>

            <div className="w-full h-full flex items-center justify-center border border-indigo-300"> 
                {
                    props.isCameraOpen && 
                    <Webcam 
                        ref={webcamRef}
                        forceScreenshotSourceSize={true}
                        onUserMediaError={(param)=>console.log('err',param)}
                        className="h-2/3"
                        videoConstraints={videoConstraints}
                    />
                }
                
            </div>

            {
                props.isCameraOpen && 
                <div className="flex flex-row w-full items-center justify-end">
                    <div className="w-1/2 text-center font-medium uppercase p-2 m-4 border bg-primary text-white" onClick={snapPhoto}>Scatta</div>
                    <div className="w-1/2 text-center font-medium uppercase p-2 m-4 border bg-primary text-white" onClick={props.close}>Annulla</div>
                </div>
            }

            

        </>
    )
  }
    
  export default CameraSnap;
    