import { Damage, Tracking } from "../types/types"
import moment from 'moment'
import Dexie from 'dexie';

let db : any;

export function initDb(){
    db = new Dexie("Tracking");
    db.version(1).stores({
        tracking_code: '++,code,date',
        tracking_damage: '++,code,date,note,base64Images'
    });
}

export function saveTrackingCode(tracking : Tracking){
    console.log('saveTrackingCode')
   let obj = {
    keyPath: tracking.code, 
    code: tracking.code, 
    date: tracking.date
   }
   console.log('obj',obj)
   db.tracking_code.add(obj)
}


export function saveDamage(damage : Damage){
    console.log('saveTrackingDamage')
   let obj = {
    keyPath: damage.code, 
    note: damage.note,
    date: damage.date,
    code: damage.code, 
    base64Images: damage.base64Images
   }
   console.log('obj',obj)
   db.tracking_damage.add(obj)
}

export async function getSavedTracking(){
    let trackingList = await db.tracking_code.toArray();
    return trackingList
}

export async function removeTracking(code : string){
    await db.tracking_code.where("code").equals(code).delete()
}

export async function getSavedDamage(){
    let damageList = await db.tracking_damage.toArray();
    return damageList
}

export async function removeDamage(code : string){
    await db.tracking_damage.where("code").equals(code).delete()
}