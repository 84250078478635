import { CircularProgress , Modal } from '@mui/material';
import React , { useState } from 'react'
import LoadingCenter from '../service/loading'

let loadingCenter = new LoadingCenter()
window.loadingCenter = loadingCenter

export default function Loading(){

    let [isLoading,setLoading] = useState(false)
    window.loadingCenter.setLoadingState = (loading)=>setLoading(loading)

    return (
        <Modal
            open={isLoading}
            >
            <div className={`flex h-full w-full items-center justify-center ${window.loadingCenter.isBackgroundColorWhite() ? 'bg-white' : ''}`}>
                <CircularProgress />
            </div>
        </Modal>
      )
}
