import {
    Cog8ToothIcon,
    ChevronLeftIcon,
    ArrowRightOnRectangleIcon
} from '@heroicons/react/24/outline'
import {goBack} from '../service/router'
import { useNavigate } from "react-router-dom";
import React,{ useEffect , useState } from 'react';
import * as loginService from '../service/login'
import { User } from '../types/types';


type HeaderProps= {
    title: string,
    logout: boolean,
    back?: Function
}

function Header(props : HeaderProps) {
    const navigate = useNavigate();
    console.log('props',props)

    let [profile,setProfile] = useState(null)

    async function getProfile(secondTry? : boolean){
        let profileResponse = await loginService.getUserProfile()

        console.log('profileResponse.status',profileResponse.status)
        if(profileResponse.status == 200){
            console.log('---1')
            let profile = await profileResponse.json()
            console.log('profile',profile)
            setProfile(profile)
            window.loadingCenter.setBackgroundColorWhite(false)
            window.loadingCenter.removeLoading()
        }else if(secondTry && (profileResponse.status == 401 || profileResponse.status == 403) ){
            console.log('---2')
            localStorage.removeItem('tracking.username')
            localStorage.removeItem('tracking.password')
            
            window.setTimeout(function(){
                window.loadingCenter.removeLoading()
                navigate('/login')
            },200)
        }else{
            console.log('---3')
            //if already try to do relogin, redirect to login page
            /*if(secondTry){
                window.location.href = '/mobile/login'
            }else{
                redoLogin()
            }*/
            if(!secondTry){
                await redoLogin()
            }
        }
        window.loadingCenter.setBackgroundColorWhite(false)
        window.loadingCenter.removeLoading()
    }

    async function redoLogin(){
        let username = localStorage.getItem('tracking.username')
        let password = localStorage.getItem('tracking.password')

        console.log('username',username)
        console.log('password',password)

        if(username == null || username=='' || password==null || password==''){
            navigate('/login')
            return
        }            

        let user : User = {
            username: username,
            password: password,
            type: 'cookie'
        }

        await loginService.doLogin(user)
        
        await getProfile(true)
    }

    async function logout(){
        let response = await loginService.logout()       
        
        localStorage.removeItem('tracking.username')
        localStorage.removeItem('tracking.password')
        
        navigate('/login')
    }

    useEffect(()=>{
        init()        
    },[])

    async function init(){
        window.loadingCenter.setBackgroundColorWhite(true)
        window.loadingCenter.addLoading(true)
        await getProfile()
    }
    return (
        <div className="flex bg-primary w-full h-16 items-center justify-between">
            <div className="h-16 w-16">
            {
                props.back &&
                <div className="h-16 w-16 flex items-center justify-center" onClick={()=>{goBack(navigate)}}>
                    <ChevronLeftIcon className="h-6 w-6 ml-2 mt-[2px] text-white text-lg cursor-pointer" />
                </div>
            }
            </div>
            <div className="text-lg font-medium uppercase text-white">{props.title}</div>
            <div className="h-16 w-16 ">
            {
                props.logout &&
                <div className="h-16 w-16 flex items-center justify-center" onClick={logout}>
                    <ArrowRightOnRectangleIcon className="h-6 w-6 mr-2 mt-[2px] text-white text-lg cursor-pointer"/>
                </div>
            }
            </div>
        </div>
    );
  }
  
  export default Header;
  