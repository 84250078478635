
let LoadingCenter = function(this: any) {
	this.loadingList = []
    this.setLoadingState = null
    this.backgroundColorWhite = false
}

declare global {
    interface Window { loadingCenter: any; }
}

LoadingCenter.prototype = {
	addLoading: function() {
		this.loadingList.push(1)

        if(this.loadingList.length == 1){
            this.setLoadingState(true)
        }
	}, 
	removeLoading: function() {
		this.loadingList.pop()

        if(this.loadingList.length == 0){
            this.setLoadingState(false)
        }
	},
    setBackgroundColorWhite: function(isBgSet : boolean){
        this.backgroundColorWhite = isBgSet
    },
    isBackgroundColorWhite: function(){
        return this.backgroundColorWhite
    }
}

export default LoadingCenter