import React , { useEffect, useState , useCallback } from 'react'
import MediaDevices from 'media-devices'
import { useZxing } from "react-zxing";
import { BarcodeFormat, DecodeHintType } from "@zxing/library";
import Select from 'react-select'
import { sendTracking } from '../service/tracking';
import Alert from "../components/Alert"
import moment from 'moment'
import * as localStorage from '../service/localStorage'
import { useNavigate } from "react-router-dom";

function QRCode(props) {
    
    const navigate = useNavigate();
    //const [scannedBarcode,setScannedBarcode] = useState([])
    const [devices,setDevices] = useState(null)
    const [scannedDeviceId,setScannedDeviceId] = useState(null)
    const [selectedDeviceId,setSelectedDeviceId] = useState(null)
    const [isScaStart,setScanStart] = useState(false)

    const [alert,setAlert] =  useState(null)

    const hints = new Map();
    const formats = [BarcodeFormat.QR_CODE]
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

    useEffect(()=>{
        //getMediaDevices()
    },[])

    const constraints = {
        video: true,
        audio: false,
      };

    const { ref } = useZxing({
        paused: !isScaStart,
        //deviceId: scannedDeviceId,
        constraint: { video: { facingMode: 'environment' }, audio: false },
        hints,
        onResult(result) {
            console.log('result',result)
            barcodeScanned(result.getText());
        },
    });

    const barcodeScanned = (text) => {
        console.log('text',text)
        if(text && text.length>2 && text.substring(0,2) != 'T1'){
            setAlert({title:'Error',message:'Barcode non valido.'})
            return
        }
        stopScanner()
        console.log('isDanno',props)
        if(props.isDanno){
            goToDanni(text)
        }else{
            sendTrackingCode(text)
        }
    }

    function goToDanni(code){
        navigate('/danno/'+code)
    }

    async function sendTrackingCode(code){

        window.loadingCenter.addLoading(true)

        let tracking = {
            code: code,
            date: moment().format('YYYYMMDDTHHmm')
        }
        console.log('tracking',tracking)
        try{
            let response = await sendTracking(tracking)
            console.log('response',response)

            window.loadingCenter.removeLoading()

            if(!response){
                localStorage.saveTrackingCode(tracking)
                let fn = ()=>{
                    navigate('/')
                }
                setAlert({title:'Tracking',message:'Codice salvato con successo!',fn: fn})
                return
            }

            if(response.status == 201){
                let fn = ()=>{
                    navigate('/')
                }
                setAlert({title:'Tracking',message:'Codice salvato con successo!',fn: fn})
                return
            }

            if(response.status == 400 || response.status == 401){
                let errorData = await response.json()
                if(errorData.message){
                    setAlert({title:'Errore',message:errorData.message})
                }else{
                    setAlert({title:'Errore',message:'Errore durante la chiamata'})                
                }
                return
            }else if(response.status == 403){
                let errorData = await response.json()
                setAlert({title:'Errore',message:errorData.message})       
                return      
            }else{
                localStorage.saveTrackingCode(tracking)
            }

            let fn = ()=>{
                navigate('/')
            }
            setAlert({title:'Tracking',message:'Codice salvato con successo!',fn: fn})


        }catch(e){
            console.log(e)
            localStorage.saveTrackingCode(tracking)
        }
        

        
    }
    
    async function startScanner(){
        //sendTrackingCode('123')
        setScanStart(true)
        //setScannedDeviceId(selectedDeviceId)
        //barcodeScanned('1234')

       /* if(props.isDanno){
            goToDanni('T11234')
        }else{
            sendTrackingCode('T11234')
        }*/
    }

    function stopScanner(){
        setScanStart(false)
        //setScannedDeviceId(null)        
    }
    
    return (
        <div className="flex flex-col items-center justify-start bg-white h-screen">
            {
                alert &&
                <Alert title={alert.title} text={alert.message} closeModal={()=>{
                    setAlert(undefined)
                    if(alert.fn){
                        alert.fn()
                    }
                }} />
            }

            <div className="w-full h-1/2 border border-indigo-300">
                <video
                    ref={ref}
                    className="w-full h-full"
                    id="videoElement"
                    style={{ border: "1px solid gray" }}
                    loop muted={true} autoPlay playsInline
                />
                
            </div>

            <div className="flex flex-col w-full items-center justify-center h-1/3">
                <div className="flex flex-col w-full items-center justify-center p-4">
                    {/*
                        devices &&
                        <Select 
                            defaultValue={devices[0]}
                            className="w-3/4"
                            options={devices}
                            onChange={(event) => {
                                console.log('onChange event',event)
                                const deviceId = event.value;
                                setSelectedDeviceId(deviceId);
                            }} />
                        */}
                </div>
                <div className="flex flex-row w-full items-center justify-center">
                    <div className="w-1/3 text-center font-medium uppercase p-2 m-4 border bg-primary text-white" onClick={startScanner}>Start</div>
                    <div className="w-1/3 text-center font-medium uppercase p-2 m-4 border bg-primary text-white" onClick={stopScanner}>Stop</div>
                </div>
            </div>

            {/*
                scannedBarcode && scannedBarcode.length > 0 &&
                <div className="font-bold uppercase">Barcode scansionati</div>
            */}

            {/*<div className="flex flex-col items-center justify-center h-1/3 w-full">
                
                <div className="flex flex-col w-screen h-full overflow-auto">
                    {
                        scannedBarcode && scannedBarcode.map((element,index)=>{
                            return (
                                <textarea key={element.key} style={{resize: "none"}} className="flex-none bg-transparent text-center justify-center w-screen overflow-hidden pt-4 h-10" readOnly value={element.text}></textarea>
                            )
                        })
                    }
                </div>
                </div>*/}
        </div>
    )
}

export default QRCode;
