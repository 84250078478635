import React , { useRef } from 'react'
import { Modal } from '@mui/material';
import Button from '../components/Button';


type AlertProps= {
    title: string,
    text: string,
    closeModal: Function
}


export default function Alert(props : AlertProps){

    function close(){
        props.closeModal()
    }
  
    return (
        <Modal
            open={true}
            >
                <div className="flex h-full w-full items-center justify-center">
                    <div className="flex flex-col h-fit w-3/4 bg-white p-4 rounded">
                        <div className="flex flex-row justify-center pb-4 font-normal border-b"><b>{`${props.title ? props.title : "ATTENZIONE"}`}</b></div>
                        <div className="flex flex-row justify-center py-4 font-normal">{props.text}</div>
                        <div className="flex flex-row items-center border-t justify-center w-full pt-4">
                            <Button text="Chiudi" clickAction={close}/>
                        </div>
                    </div>
                </div>
        </Modal>
    )
}
