import React,{ useEffect , useState } from 'react';

function Footer() {
    return (
        <div className="w-4/5 pt-8">
            <img className="w-full max-w-xs" src="/montorfano_logo_blu.png"/>
        </div>
    );
  }
  
  export default Footer;
  