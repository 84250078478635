import { createBrowserRouter } from "react-router-dom"
import Homepage from '../views/Homepage'
import QRCode from '../views/QRCode'
import Camera from '../views/Camera'
import Error from '../views/Error'
import Template from '../views/Template'
import Login from "../views/Login"


function wrapWithTemplate(component,props){
    return <Template {...props} >{component}</Template>
}


export function getRouterConfiguration(){
    return createBrowserRouter([
        {
            path: "/login", element: <Login />, errorElement: <Error />
        },
        {
          path: "/", element: wrapWithTemplate(<Homepage />,{title:"Tracking",back:false,logout:true,footer:true}), errorElement: <Error />
        },
        {
            path: "/qrcode", element: wrapWithTemplate(<QRCode />,{title:"Scansiona QRCode",back:true,isDanno:false}), errorElement: <Error />
        },
        {
            path: "/danno", element: wrapWithTemplate(<QRCode />,{title:"Segnala Danno",back:true,isDanno:true}), errorElement: <Error />
        },
        {
            path: "/danno/:code", element: wrapWithTemplate(<Camera />,{title:"Scatta Immagine",back:true}), errorElement: <Error />
        }
      ],{
        basename: '/mobile',
      })
}

export function goToQRCode(navigate){
    navigate('/qrcode')
}

export function goToCamera(navigate){
    navigate('/danno')
}

export function goBack(navigate){
    if(window.location.href.indexOf('/mobile/danno/')){
        navigate('/')
    }else{
        navigate(-1)
    }
}