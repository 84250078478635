
export type FetchParamsPost = {
    url : string,
    body? : { [key: string]: String | String[] | number | boolean | File }
  }
  
  
  export type FetchParamsGet = {
    url : string,
    params?: Record<string,string | number>
  }

  
  
  export const post = async function(config : FetchParamsPost):Promise<Response | null>{

    let HTTP_TIMEOUT = 3000;
    if(config.url.indexOf('/api/damage')>-1){
      HTTP_TIMEOUT = 40000;
    }

    
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), HTTP_TIMEOUT);

  
    let options = {
      signal: controller.signal,
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "same-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      //body: body, // body data type must match "Content-Type" header
    } as RequestInit
  
  
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
  
    if(config.body){
      options.body = JSON.stringify(config.body)
    }
  
    options.headers = requestHeaders
  
    let response = null

    try{
      response = await fetch(config.url, options);
    }catch(e){
      console.error(e)
    }finally{
      clearTimeout(timeoutId);
    }

    return response    
  }
  
  
  export const put = async function(config : FetchParamsPost):Promise<Response>{
  
    let options = {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "same-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      //body: body, // body data type must match "Content-Type" header
    } as RequestInit
  
  
    const requestHeaders: HeadersInit = new Headers();
    //requestHeaders.set('Content-Type', 'application/json');
  
    
    if(config.body){
      options.body = JSON.stringify(config.body)
    }
  
    options.headers = requestHeaders
  
    const response = await fetch(config.url, options);
    return response    
  }
  
  
  export const deleteFetch = async function(config : FetchParamsPost):Promise<Response>{
  
    let options = {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "same-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      //body: "{}", // body data type must match "Content-Type" header
    } as RequestInit
  
  
    const requestHeaders: HeadersInit = new Headers();
    
    //requestHeaders.set('Content-Type', 'application/json');
  
  
    options.headers = requestHeaders
  
    const response = await fetch(config.url, options);
    return response    
  }
  
  export const buildUrl = function(config : FetchParamsGet){
    let url = config.url
    if(config.params){
      url += '?'
      let keys = Object.keys(config.params)
      for(let i=0;i<keys.length;i++){
        let value = config.params[keys[i]]
        if(value==''){
          continue;
        }
        url += keys[i] + '=' + value
        if(i!=keys.length-1){
          url += '&'
        }
      }
    }
    return url
  }
  
  
  export const get = async function(config : FetchParamsGet):Promise<Response>{
  
    let HTTP_TIMEOUT = 3000;

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), HTTP_TIMEOUT);

    let url = buildUrl(config)
    
    console.log('url',url)
    const response = await fetch(url, {
        signal: controller.signal,
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "same-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

    clearTimeout(timeoutId)
    return response    
  }