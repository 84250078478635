import * as trackingService from './tracking'
export function startScheduler(){
    
    if(!window.schedulerIsRunning){
        let timer = setInterval(function(){
            console.log('scheduler - running' , new Date())
            trackingService.processSavedTracking()
        },1000 * 10) //millis * second * minutes
    }

    window.schedulerIsRunning = true
}