import { useState } from "react";
import { goToCameraSnap } from "../service/router";
import { useNavigate , useParams } from "react-router-dom";
import CameraSnap from "../components/camera/CameraSnap";
import * as damageService from '../service/damage'
import * as localStorageService from '../service/localStorage'
import Alert from "../components/Alert"
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';

function Camera() {
  const navigate = useNavigate();

  let { code } = useParams()

  const [alert,setAlert] =  useState(null)

  let [note,setNote] = useState('')
  let [images,setImages] = useState([])
  const [isCameraOpen,setCameraOpen] = useState(false)

  function snapPhoto(){
    setCameraOpen(true)
  }

  function saveImage(base64){
    console.log('---',base64)
    setCameraOpen(false)
    setImages([{src:base64,id:new Date().getTime()},...images])
  }

  function sendDanno(){
    console.log(images)

    if(images.length == 0){
      setAlert({title:'Danno',message:'Almeno un\'immagine è obbligatoria'})
      return
    }

    let uuid = uuidv4();

    let damage = {
      note: note + '|&|' + uuid,
      code: code,
      date: moment().format('YYYYMMDD'),
      base64Images: []
    } 

    for(let i=0;i<images.length;i++){
      let image = images[i]
      damage.base64Images.push(image.src)
    }
    sendDamageToServerAsync(damage)
    let fn = ()=>{
      navigate('/')
    }
    setAlert({title:'Danno',message:'Danno salvato con successo!',fn: fn})
  }

  async function sendDamageToServerAsync(damage){

    let response = await damageService.sendDamage(damage)
    
    if(response && response.status == 403){
      setAlert({title:'Errore',message:'Utente non autorizzato',fn: fn});
      return;
    }

    if(!response || response.status != 201){
      localStorageService.saveDamage(damage)
    }
  }
  return (
    <div className="flex flex-col items-center justify-start bg-white h-screen">

          {
              alert &&
              <Alert title={alert.title} text={alert.message} closeModal={()=>{
                  setAlert(undefined)
                  if(alert.fn){
                      alert.fn()
                  }
              }} />
          }

          <textarea onChange={e => setNote(e.target.value)} className="w-full mt-2 mb-2" placeholder="Nota sul danno" />
          
          <div className="w-full h-1/2 border border-indigo-300"> 
              <CameraSnap isCameraOpen={isCameraOpen} saveImage={saveImage} close={()=>setCameraOpen(false)}/>
          </div>

          {
            !isCameraOpen &&
            <>
              
              <div className="flex flex-row w-full items-center justify-center">
                <div className="w-1/2 text-center font-medium uppercase p-2 m-4 border bg-primary text-white cursor-pointer" onClick={snapPhoto}>Scatta Foto</div>
                <div className="w-1/2 text-center font-medium uppercase p-2 m-4 border bg-primary text-white cursor-pointer" onClick={sendDanno}>Invia Danno</div>
              </div>
              <div className="flex flex-wrap">
                {
                  images.map((image)=>{
                    return(
                      <div key={image.id} className="w-1/3 max-w-1/3"><img className="p-2" src={image.src}></img></div>
                    )
                  })
                }
              </div>
            </>
          }
          

    </div>
  )
}
  
export default Camera;
  