import { RouterProvider } from "react-router-dom";
import { getRouterConfiguration } from './service/router'
import Loading from "./components/Loading";

function App() {
  return (
    <div>
      <Loading />
      <RouterProvider router={getRouterConfiguration()} />
    </div>
  );
}

export default App;
