import { Damage, Tracking } from '../types/types'
import * as fetch from '../utility/fetch'
import * as localStorageService from './localStorage'
import * as damageService from './damage'

export const sendTracking = async function(tracking : Tracking){
    let params : fetch.FetchParamsPost = {
      url: '/api/tracking',
      body: tracking
    }
  
    const response = await fetch.post(params)
  
    return response
}

export async function processSavedTracking(){
  let savedTracking = await localStorageService.getSavedTracking()
  console.log('savedTracking',savedTracking)

  
  if(savedTracking && savedTracking.length > 0){
    for(let i=0;i<savedTracking.length;i++){
      let tracking = savedTracking[i]
      let response = await sendTracking(tracking)

      if(response && (response.status == 201 || response.status == 400) ){
        await localStorageService.removeTracking(tracking.code)
      }
    }
  }

  let savedDamage = await localStorageService.getSavedDamage()
  console.log('savedDamage',savedDamage)


  if(savedDamage && savedDamage.length > 0){
    for(let i=0;i<savedDamage.length;i++){
      let damage = savedDamage[i]
      console.log('damage',damage)
      let response = await damageService.sendDamage(damage)

      if(response && ( response.status == 201 || response.status == 403) ){
        await localStorageService.removeDamage(damage.code)
      }
    }
  }
}